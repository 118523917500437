.input:autofill {
  background: transparent;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.input {
  animation-name: onAutoFillStart;
  @apply bg-transparent;
}

/*Empty keyframes are intentional, they are needed to detect Chrome's autofill*/
@keyframes onAutoFillStart {
}

.input-line-animation {
  @apply relative;
}

.input ~ .inputBorder {
  @apply absolute bottom-0 left-0 h-px w-0 bg-current opacity-0;
}

.input:hover ~ .inputBorder {
  @apply z-20 w-full opacity-100 duration-500;
}
