.Stars {
  --percent: calc(var(--rating) / 5 * 100%);
  
  display: inline-block;
  font-family: Times;
  line-height: 1;
  
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, theme('colors.sg.black') var(--percent), theme('colors.sg.grey') var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}