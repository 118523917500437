.nativeScrollbar {
  appearance: auto;
  -webkit-appearance: auto;
  // Mozilla does not support ::webkit scrollbar styling so leaving reasonable defaults for firefox
  @supports (-moz-appearance:none) {
  scrollbar-width: thin;
  scrollbar-color: theme('colors.sg.black') transparent;
  }

  &::-webkit-scrollbar {
    width: 20px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: theme('colors.sg.grey');
  }

  &::-webkit-scrollbar-thumb {
    background: theme('colors.sg.black');
    border-radius: 5px;
    border: 3px solid theme('colors.sg.black');
  }

  &::-webkit-scrollbar-thumb:hover {
    background: theme('colors.sg.grey');
  }
}
